import React, {useEffect} from "react";
import {BrowserRouter, Route, Link} from "react-router-dom";
// import axios from "axios";

// IMAGES
import im from "./images/gggggg.png";
// PAGES
import About from "./pages/About";

import "./App.css";
//PAGE LAYOUT
import Page from "./layout/Page.layout";


const Users = () => {
    // state
    const [users, setUsers] = React.useState([]);
    // effects
    React.useEffect(() => {
        fetch("https://jsonplaceholder.typicode.com/users")
            .then((res) => {
                return res.json();
            })
            .then(data => {
                setUsers(data)
            })
            .catch((err) => {
            });
    }, []);
    // render
    return (
        <div>
            <h2>Users</h2>
            <Link to="/about">About</Link>
            <ul>
                {users.map((user) => (
                    <li key={user.id}>
                        {user.name} ({user.email})
                    </li>
                ))}
            </ul>
            <h3>text</h3>
            <h4>text safasdfasdf</h4>
            <img style={{height: "50px", width: "50px"}} src={im} alt="some pic"/>
        </div>
    );
};

const App = () => {
    useEffect(() => {
        const splas = document.getElementById("fuse-splash-screen");
        if(splas) {
            splas.removeAttribute("id");
        }
    }, [])
    return (
        <BrowserRouter>
            <Route path="/" exact>
                <Page title="Users"><Users/></Page>
            </Route>
            <Route path="/about" exact>
                <Page title="About"><About/></Page>
            </Route>
        </BrowserRouter>
    )
};
export default App;