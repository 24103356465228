import React, {useEffect} from "react"
import { Link } from "react-router-dom";

const AboutPage = () => {
    useEffect(() => {
    }, [])
    return (
        <section>
            <div>
                about page
            </div>
            <Link to="/">Go Home page</Link>
            <img height="100px" width="100px" src="https://images.unsplash.com/photo-1541963463532-d68292c34b19?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxleHBsb3JlLWZlZWR8Mnx8fGVufDB8fHx8&auto=format&fit=crop&w=500&q=60" alt="other pic"/>
        </section>
    )
}

export default AboutPage